<template>
    <div class="smsSettings">
        <div class="ml-20 mt-20 mb-20">状态查询(新)</div>
        <div class="flex-nowrap-space-around-other">
            <div class="input-searchFrom-width-600">
                <el-form ref="upform" size="small" :model="upform" label-width="120px" :rules="rules">
                    <el-form-item label="接口账户：" prop="interfaceId">
                        <el-select
                            v-model="upform.interfaceId"
                            placeholder="请选择查询的接口账户"
                            style="width: 100%"
                            clearable
                            filterable 
                            >
                            <el-option
                                v-for="(item, index) in listArr"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="号码列表：" prop="number">
                        <el-input
                            v-model="upform.number"
                            type="textarea"
                            placeholder="请输入手机号(换行可输入多个)"
                            :autosize="{ minRows: 6, maxRows: 8 }"
                        />
                        <div>一行一个号码</div>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button class="yes-green-bg" @click="theQuery('upform')">查 询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="input-searchFrom-width-600 show-boxs">
                <div v-if="dataList.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div v-else>
                    <div v-for="(item,i) in dataList"  :key="i" class="flex-nowrap-space-around item-box-other">
                        <div>{{ item.number }}</div>
                        <div>号码状态:{{ item.statusName }}</div>
                        <div>激活状态:{{ item.active == 1
                                        ? '激活'
                                        : item.active == 2
                                        ? '未激活'
                                        : '未知' }}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import ctcc from '@/api/open/ctcc'
export default {
    components: {

    },
    data () {
        const checkNumber = (rule, value, callback) => {
            let num = 0,types = null,errorItem;
            if(value == null){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else if(value.length == 0){
                callback(new Error('请填写号码 (多个号码请换行输入)'))
            }else{
                let newArr = []
                newArr = value.split(/[(\r\n)\r\n]+/)
                newArr.forEach((item,i)=>{
                    if(item.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/) == null){
                    // if(item.match(/^(1[3|4|5|6|7|8|9])\\d{9}$|^0\\d{2,3}-?\\d{7,8}$|^9\\d{9}/) == null){
                        // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
                        num++
                        types = i
                        errorItem = item
                    }
                })
                if( num != 0 ){
                    callback(new Error('请输入正确的号码 (请检查第'+(types+1)+"行："+ errorItem +")"))
                }else{
                    callback()
                }
            }
        }
        return {
            upform:{},
            listArr:[{
                name:'XZ电信回拨(新)',
                id:1912
            }
            
            ,{
                name:'DF电信回拨(新)',
                id:2516
            }
          
          ],
            dataList:[],
            rules: {
                interfaceId: [
                    {
                    required: true,
                    message: '请选择查询的接口账户',
                    trigger: 'change',
                    },
                ],
                number: [
                    { required: true, validator: checkNumber, trigger: 'blur' },
                ],
            }
        }
    },
    created() {
        
    },
    watch: {

    },
    mounted() {

    },
    methods: {
       theQuery(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let upArr = []
                    let arrLest = this.upform.number.split(/[(\r\n)\r\n]+/)
                    this.unique(arrLest).forEach((item,i) =>{
                        upArr.push(item.trim())
                    })
                    ctcc.ctccStatusNew({
                        interfaceId:this.upform.interfaceId,
                        numberList:upArr
                    }).then(res => {
                        if(res.code == 200){
                            this.dataList = res.data
                        }
                    })
                } else {
                console.log('error submit!!')
                return false
                }
            })
        },
        unique(arr) {
            const res = new Map();
            return arr.filter((a) => !res.has(a) && res.set(a, 1))
        },
    }
}
</script>

<style lang="scss" scoped>
    // .smsSettings{
    //     // margin-top: 60px;
    // }
    .show-boxs{
        height: 100%;
        border: 1px solid #0000;
    }
    .item-box-other{
        padding: 8px 16px;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #ebebeb;
    }
</style>